module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-tracking/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"UA-44621349-2","head":true,"anonymize":true,"autoStart":false},"googleAds":{"trackingId":"","anonymize":true},"hotjar":{"trackingId":"1772519","snippetVersion":"6"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"","short_name":"profilium.de","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"/vercel/path0/src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"6dbce3751bc15e3e09244ddc2c46207d"},
    },{
      plugin: require('../node_modules/gatsby-theme-sanity-evelan/gatsby-browser.js'),
      options: {"plugins":[],"sanityProjectId":"u4bjw6ko","sanityDataset":"production","sanityReadToken":"skbViAotX5yOWhjIjP8FSIUKzsLsRQAnsLgOCQysqm0lhBQw2z3SFxYBW7w5VxQijDXSGOIeg9eUENhOEPJObYc5bTFAAVzD3KrmpNkw00r8MaHmZc1lFyy1wfkJB5QeLAYUwzCEo0PyA4keYBpCVlBlVzqoOHcnMiVHf1jay15rkrGqTw5m","enableWatchMode":false,"googleAnalyticsTrackId":"UA-44621349-2","googleAnalyticsAutoStart":false,"hotjarTrackId":"1772519","manifestShortName":"profilium.de","basePath":"/vercel/path0","blogPageLimit":9,"host":"https://profilium.de","siteMap":"https://profilium.de","favicon":"favicon.png"},
    }]
